import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../../style/overview.css';
import BillingNav from '../dashboard/billing-nav';
import { Link, useLocation } from 'react-router-dom';


const Overview = ({ onLogout, user }) => {
  const [plan, setPlan] = useState(null);

  useEffect(() => {
    handleOverview();
  }, []);

  const handleOverview = async () => {
    try {
      const response = await axios.post('https://threepio.io/billing/get_overview/', {
        username: user.username,
      });
      setPlan(response.data.plan_name);
    } catch (error) {
      console.error('Error fetching plans: ', error);
    }
  };

  return (
    <>
      {/* <BillingNav /> */}
        <div className="billing-page">
          <h1 className="billing-header">Billing</h1>
          
          <div className="content-container">
            <div className="payment-container">
              <div className="payment-box">
                  <h2 className="payment-title">My Payment Plan</h2>
                  <hr className="divider" />
                  <p className="payment-info">Pay As You Go (free)</p> {/*this is hardcoded -- emily*/}
                  <p className="credit-balance">Credit balance: <span>$10.00</span></p> {/*this is hardcoded -- emily*/}
                  <div className="button-container">
                    <button className="btn">Add to Credit Balance</button> {/*need stripe path and whatnot idk if this has been built out yet -- emily*/}
                    <a href="/dashboard/billing/plans"><button className="btn">Change Payment Plan</button></a>
                  </div>
              </div>
            </div>
            
          
            <div className="billing-options">
              <a href="/dashboard/billing/plans"><i className="fa fa-dollar dollar-shift"></i> Payment Plans</a>
              <a href="/dashboard/billing/billing-history"><i className="fa fa-clock-o clock-shift"></i> Billing History</a>
              <a href="/dashboard/billing/billing-preferences"><i className="fa fa-credit-card"></i> Billing Preferences</a>
            </div>
          </div>
        </div>
        
        {/* <h1>Overview</h1>
        <div>
          <h2>Current Plan: {plan}</h2>
        </div>
        <button onClick={() => (window.location.href = '/dashboard/billing/plans')}>
          Select Plan OR Cancel Plan
        </button> */}
        {/* Add more content here as needed */}
    </>
  );
};

export default Overview;
