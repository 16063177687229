import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../style/login.css';
import Navbar from './navbar';
import { ip_addr } from './settings';

const Login = ({ onLogin }) => {
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    try {
      // let token = getCookie('csrftoken');
      const response = await axios.post('https://api.threepio.io/accounts/login/', formData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });

      if (response.data.success) {
        console.log('User logged in successfully', response.data);
        const userData = {
          username: response.data.username,
          profilePicture: 'profile-picture-url'
        };
        onLogin(userData);
        navigate('/dashboard/database', { state: userData });
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      if (error.response) {
        console.error('Error response:', error.response);
        setError(`Error: ${error.response.data.message || 'An error occurred during login.'}`);
      } else {
        console.error('Error:', error);
        setError('An error occurred during login.');
      }
    }
  };

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    setIsAuthenticated(false);
  };

  return (
    <div className = "login">
       <Navbar isAuthenticated={isAuthenticated} handleLogout={handleLogout} />
      <div className="login-container">
        <form className="form-box" onSubmit={handleSubmit}>
          <h2>Login</h2>
          <div className="input-field">
            <label>Username:</label>
            <input type="text" name="username" placeholder="Username" required />
          </div>
          <div className="input-field">
            <label>Password:</label>
            <input type="password" name="password" placeholder="Password" required />
          </div>
          <div className="button-group">
            <button type="submit" className="button-primary">Login</button>
          </div>
          {error && <p className="error-message">{error}</p>}
          <p>If you don't have an account, <a className="text-link" href="/signup">Sign Up</a></p>
        </form>
      </div>
    </div>
  );
};

export default Login;
