import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../style/Folders.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder } from '@fortawesome/free-solid-svg-icons';
import Layout from '../Layout';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';

Modal.setAppElement('#root');

const Folders = ({ onLogout, user }) => {
  const [folders, setFolders] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false)
  const [renameFolderId, setRenameFolderId] = useState(null);
  const [renameFolderName, setRenameFolderName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchFolders();
  }, []);

  const fetchFolders = async () => {
    try {
      const response = await axios.get('https://api.threepio.io/transcribe/folders/');
      console.log("RESPONSE FROM FETCHFOLDER: ", response.data);
      console.log("This is the actual response", response);
      setFolders(response.data);
    } catch (error) {
      console.error('Error fetching folders:', error);
    }
  };

  const handleCreateFolder = async () => {
    if (newFolderName.trim()) {
      try {
        const response = await axios.post('https://api.threepio.io/transcribe/folders/create/', { name: newFolderName });
        if (response.data && response.data.error) {
          setErrorMessage(response.data.error);
        } else {
          setIsCreateModalOpen(false);
          setNewFolderName('');
          setErrorMessage('');
          fetchFolders(); // Refresh the folders list
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
          setErrorMessage(error.response.data.error);
        } else {
          console.error('Error creating folder:', error);
        }
      }
    } else {
      setErrorMessage('Folder name is required');
    }
  };

  const handleRenameFolder = async () => {
    if (renameFolderName.trim()) {
      try {
        await axios.post(`https://api.threepio.io/transcribe/folders/${renameFolderId}/rename/`, { name: renameFolderName });
        setIsRenameModalOpen(false);
        setRenameFolderId(null);
        setRenameFolderName('');
        setErrorMessage('');
        fetchFolders(); // Refresh the folders list
      } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
          setErrorMessage(error.response.data.error);
        } else {
          console.error('Error renaming folder:', error);
        }
      }
    } else {
      setErrorMessage('Folder name is required');
    }
  };

  const handleFolderClick = (folderId) => {
    navigate(`/dashboard/folders/${folderId}`);
  };

  const openRenameModal = (folderId, folderName) => {
    setRenameFolderId(folderId);
    setRenameFolderName(folderName);
    setIsRenameModalOpen(true);
  };

  return (
    <Layout onLogout={onLogout} user={user}>
      <div className="folders-container">
        <div className="folders-header">
          <h1>Folders</h1>
          <button className="create-folder-button" onClick={() => setIsCreateModalOpen(true)}>
            Create Folder
          </button>
        </div>
{/* Emily you need to fix this and literally just combine all the features of the two files and then combine their ui */}
        {/* <input
          type="text"
          placeholder="Search videos..."
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          className="search-input"
        /> */}
        <Link onClick={() => setIsCreateModalOpen(true)}><i className="fa fa-plus trash" aria-hidden="true"></i></Link>
        {/* <Link to="/dashboard/folders">
            <i className="fa fa-plus trash" aria-hidden="true"></i>
          </Link>  */}
          <Link to="/dashboard/trash">
            <i className="fa fa-trash-o trash" aria-hidden="true"></i>
          </Link>    
        <ul className="folders-list">
          {folders.map((folder) => (
            <li key={folder.id} className="folder-item">
              <div className="folder-info" onClick={() => handleFolderClick(folder.id)}>
                <FontAwesomeIcon icon={faFolder} className="folder-icon" />
                {folder.name}
              </div>
              <button className="rename-folder-button" onClick={() => openRenameModal(folder.id, folder.name)}>
                Rename
              </button>
            </li>
          ))}
        </ul>

        <Modal
          isOpen={isCreateModalOpen}
          onRequestClose={() => setIsCreateModalOpen(false)}
          contentLabel="Create Folder"
          className="Modal"
          overlayClassName="Overlay"
        >
          <h2>Create Folder</h2>
          <input
            type="text"
            value={newFolderName}
            onChange={(e) => setNewFolderName(e.target.value)}
            placeholder="Folder name"
            className="create-folder-input"
          />
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <button onClick={handleCreateFolder} className="create-folder-button">Create</button>
          <button onClick={() => setIsCreateModalOpen(false)} className="cancel-button">Cancel</button>
        </Modal>

        <Modal
          isOpen={isRenameModalOpen}
          onRequestClose={() => setIsRenameModalOpen(false)}
          contentLabel="Rename Folder"
          className="Modal"
          overlayClassName="Overlay"
        >
          <h2>Rename Folder</h2>
          <input
            type="text"
            value={renameFolderName}
            onChange={(e) => setRenameFolderName(e.target.value)}
            placeholder="New folder name"
            className="rename-folder-input"
          />
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <button onClick={handleRenameFolder} className="rename-folder-button">Rename</button>
          <button onClick={() => setIsRenameModalOpen(false)} className="cancel-button">Cancel</button>
        </Modal>
      </div>
    </Layout>
  );
};

export default Folders;
