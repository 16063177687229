import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from '../Layout';
import Overview from '../billing/Overview';
import Plans from '../billing/Plans';
import PaymentMethods from '../billing/payment-methods';
import BillingHistory from '../billing/billing-history';
import BillingPreferences from '../billing/billing-preferences';

const Billing = ({ onLogout, user }) => {
  return (
    <Layout onLogout={onLogout} user={user}>
      <Routes>
        <Route path="/" element={<Overview user={user} onLogout={onLogout} />} />
        <Route path="overview" element={<Overview user={user} onLogout={onLogout} />} />
        <Route path="plans" element={<Plans user={user} onLogout={onLogout} />} />
        <Route path="payment-methods" element={<PaymentMethods user={user} onLogout={onLogout} />} />
        <Route path="billing-history" element={<BillingHistory user={user} onLogout={onLogout} />} />
        <Route path="billing-preferences" element={<BillingPreferences user={user} onLogout={onLogout} />} />
      </Routes>
    </Layout>
  );
};

export default Billing;
