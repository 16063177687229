import React from 'react';
import '../../style/overview.css';
// import BillingNav from '../dashboard/billing-nav';

const BillingPreferences = ({ onLogout, user }) => {
  return (
    <>
      {/* <BillingNav /> */}
      <a href="/dashboard/billing" className="breadcrumb"><i className="fas fa-arrow-left"></i>Back to Billing Overview</a>
      <div className="secondary-padding">
        <h2 className="billing-header">Billing Preferences</h2>
        <p className="billing-subtitle">Manage your billing preferences here.</p>
      </div>
    </>
  );
};

export default BillingPreferences;
