import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from './navbar';
import '../style/home.css';
import { FaInfoCircle } from 'react-icons/fa';

const Home = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [billingCycle, setBillingCycle] = useState('monthly');
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    setIsAuthenticated(false);
  };

  const toggleBillingCycle = (cycle) => {
    setBillingCycle(cycle);
  };

  const handleSubtitlingClick = () => {
    navigate('/login');
    
  };

  return (
    <div className="homepage">
      <Navbar isAuthenticated={isAuthenticated} handleLogout={handleLogout} />
      <div className="hero-container">
        <section className="hero">
          <h1>Threepio</h1>
          <p>Unlocking Global Stories</p>
          <button className="cta-button" onClick={handleSubtitlingClick}>Get subtitling →</button>
        </section>
      </div>
      <main className="main">
        <section className="features">
          <div className="feature">
            <div className="feature-header">
              <FaInfoCircle size={32} />
              <h2>Transcribe</h2>
            </div>
            <div className="feature-content">
              <p>
                Leverage the power of AI for seamless transcriptions in over 100 languages and dialects. Our service ensures market-leading accuracy, especially for languages with many dialects, like Arabic and Hindi.
              </p>
            </div>
          </div>
          <div className="feature">
          <div className="feature-header">
              <FaInfoCircle size={32} />
              <h2>Subtitles</h2>
            </div>
            <div className="feature-content">
              <p>
                Enhance your videos with AI-generated, context-aware subtitles in any language. Tailored to the content type, use case, and duration, our subtitles adapt to every scene, ensuring clarity and engagement, whether for ads, documentaries, or any other format.
              </p>
            </div>
          </div>
          <div className="feature">
          <div className="feature-header">
              <FaInfoCircle size={32} />
              <h2>Translate</h2>
            </div>
            <div className="feature-content">
              <p>
                Break language barriers with our advanced AI translations. Designed to understand cultural nuances and idiomatic expressions, our service supports over 100 languages and dialects, ensuring your message resonates globally.
              </p>
            </div>
          </div>
        </section>
      </main>
      <section className="pricing">
        <div className="pricing-toggle">
        <button
            className={`toggle-button ${billingCycle === 'monthly' ? 'active' : ''}`}
            onClick={() => toggleBillingCycle('monthly')}
          >
            Monthly
          </button>
          <button
            className={`toggle-button ${billingCycle === 'yearly' ? 'active' : ''}`}
            onClick={() => toggleBillingCycle('yearly')}
          >
            Yearly
          </button>
        </div>
        <div className="pricing-cards">
          <div className="card">
            <h3>Pay-as-you-go</h3>
            <p className="price">$0 <span class="date">/month</span></p>
            <ul>
              <li>AI-powered transcription, translation, and subtitles</li>
              <li>120 minutes of subtitles a month</li>
              <li>Purchase additional minutes at rate of $0.25/minute</li>
              <li>Download transcriptions in Word and TXT</li>
              <li>Download subtitles in SRT</li>
            </ul>
            <button className="card-button">Get Started →</button>
          </div>
          <div className="card">
            <h3>Basic</h3>
            <p className="price">${billingCycle === 'monthly' ? '16' : '9.60'} <span class="date">/month</span></p>
            <ul>
              <li>AI-powered transcription, translation, and subtitles</li>
              <li>120 minutes of subtitles a month</li>
              <li>Purchase additional minutes at rate of $0.20/minute</li>
              <li>Download transcriptions in Word and TXT</li>
              <li>Download subtitles in SRT</li>
              <li>Video search and analytics function</li>
            </ul>
            <button className="card-button">Get Started →</button>
          </div>
          <div className="card">
            <h3>Pro</h3>
            <p className="price">${billingCycle === 'monthly' ? '28' : '16.80'} <span class="date">/month</span></p>
            <ul>
              <li>AI-powered transcription, translation, and subtitles</li>
              <li>300 minutes of subtitles a month</li>
              <li>Purchase additional minutes at rate of $0.15/minute</li>
              <li>Download transcriptions in Word and TXT</li>
              <li>Download subtitles in SRT</li>
              <li>Video search and analytics function</li>
            </ul>
            <button className="card-button">Get Started →</button>
          </div>
          <div className="card">
            <h3>Business</h3>
            <p className="price">${billingCycle === 'monthly' ? '52' : '31.20'} <span class="date">/month</span></p>
            <ul>
              <li>AI-powered transcription, translation, and subtitles</li>
              <li>600 minutes of subtitles a month</li>
              <li>purchase up to 10 extra hours</li>
              <li>Download transcriptions in Word and TXT</li>
              <li>Download subtitles in SRT</li>
              <li>Video search and analytics function</li>
              <li>Team collaboration</li>
            </ul>
            <button className="card-button">Get Started →</button>
          </div>
        </div>
      </section>
      <footer className="footer">
        <div className="footer-logo">Threepio</div>
        <div className="footer-columns">
          <div className="footer-column">
            <h4>Product</h4>
            <ul>
              <li><a href="#transcription">Transcription</a></li>
              <li><a href="#translation">Translation</a></li>
              <li><a href="#subtitles">Subtitles</a></li>
            </ul>
          </div>
          <div className="footer-column">
            <h4>Explore</h4>
            <ul>
              <li><a href="#design">Design</a></li>
              <li><a href="#prototyping">Prototyping</a></li>
              <li><a href="#development">Development features</a></li>
              <li><a href="#collaboration">Collaboration features</a></li>
              <li><a href="#process">Design process</a></li>
            </ul>
          </div>
          <div className="footer-column">
            <h4>Resources</h4>
            <ul>
              <li><a href="#blog">Blog</a></li>
              <li><a href="#best-practices">Best practices</a></li>
              <li><a href="#support">Support</a></li>
              <li><a href="#developers">Developers</a></li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Home;
