import React from 'react';
import { Route, Routes, Outlet } from 'react-router-dom';
import Layout from './Layout';
import Database from './dashboard/Database';

const Dashboard = ({ onLogout, user }) => {
  return (
    <Layout onLogout={onLogout} user={user}>
      <Routes>
        <Route path="database" element={<Database user={user} />} />
      </Routes>
      <Outlet />
    </Layout>
  );
};

export default Dashboard;
