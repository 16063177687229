import React, { useState, useEffect } from 'react';
import '../style/Pricing.css';
import Navbar from './navbar';

const Pricing = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [billingCycle, setBillingCycle] = useState('monthly');

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    setIsAuthenticated(false);
  };

  const toggleBillingCycle = (cycle) => {
    setBillingCycle(cycle);
  };

  return (
    <div className="pricing-page">
      <Navbar isAuthenticated={isAuthenticated} handleLogout={handleLogout} />
      <h1 className='subtext-bold'>Find Your Perfect Plan</h1>
      <div className="pricing-toggle">
        <button
          className={`toggle-button ${billingCycle === 'monthly' ? 'active' : ''}`}
          onClick={() => toggleBillingCycle('monthly')}
        >
          Monthly
        </button>
        <button
          className={`toggle-button ${billingCycle === 'annually' ? 'active' : ''}`}
          onClick={() => toggleBillingCycle('annually')}
        >
          Annually <span>Save 40%</span>
        </button>
      </div>
      <div className="pricing-cards">
        <div className="card">
          <h3>Pay-as-you-go</h3>
          <p className="description">To try out our platform and see if it fits your needs.</p>
          <div className="price-wrapper">
            <p className="price">$0</p>
            <p className="subtext">Free forever</p>
          </div>
          <ul>
            <li>AI-powered transcription, translation, and subtitles</li>
            <li>120 minutes of subtitles a month</li>
            <li>Purchase additional minutes at rate of $0.25/minute</li>
            <li>Download transcriptions in Word and TXT</li>
            <li>Download subtitles in SRT</li>
          </ul>
          <div className="button-wrapper">
            <button className="card-button">Get Started →</button>
          </div>
        </div>
        <div className="card">
          <h3>Basic</h3>
          <p className="description">For individuals with basic transcription, subtitling, or translation needs.</p>
          <div className="price-wrapper">
            <p className="price">${billingCycle === 'monthly' ? '16' : '9.60'}</p>
            <p className="subtext">Per {billingCycle === 'monthly' ? 'month · Billed monthly' : 'month · Billed annually'}</p>
          </div>
          <ul>
            <li>AI-powered transcription, translation, and subtitles</li>
            <li>120 minutes of subtitles a month</li>
            <li>Purchase additional minutes at rate of $0.20/minute</li>
            <li>Download transcriptions in Word and TXT</li>
            <li>Download subtitles in SRT </li>
            <li>Video search and analytics function</li>
          </ul>
          <div className="button-wrapper">
            <button className="card-button">Get Started →</button>
          </div>
        </div>
        <div className="card">
          <h3>Pro</h3>
          <p className="description">For professionals with more recurring language needs.</p>
          <div className="price-wrapper">
            <p className="price">${billingCycle === 'monthly' ? '28' : '16.80'}</p>
            <p className="subtext">Per {billingCycle === 'monthly' ? 'month · Billed monthly' : 'month · Billed annually'}</p>
          </div>
          <ul>
            <li>AI-powered transcription, translation, and subtitles</li>
            <li>300 minutes of subtitles a month</li>
            <li>Purchase additional minutes at rate of $0.15/minute</li>
            <li>Download transcriptions in Word and TXT</li>
            <li>Download subtitles in SRT</li>
            <li>Video search and analytics function</li>
          </ul>
          <div className="button-wrapper">
            <button className="card-button">Get started →</button>
          </div>
        </div>
        <div className="card">
          <h3>Business</h3>
          <p className="description">For professionals and teams with large recurring language needs.</p>
          <div className="price-wrapper">
            <p className="price">${billingCycle === 'monthly' ? '52' : '31.20'}</p>
            <p className="subtext">Per{billingCycle === 'monthly' ? 'month · Billed monthly' : 'month · Billed annually'}</p>
          </div>
          <ul>
            <li>AI-powered transcription, translation, and subtitles</li>
            <li>600 minutes of subtitles a month</li>
            <li>purchase up to 10 extra hours</li>
            <li>Download transcriptions in Word and TXT</li>
            <li>Download subtitles in SRT</li>
            <li>Video search and analytics function</li>
            <li>Team collaboration</li>
          </ul>
          <div className="button-wrapper">
            <button className="card-button">Get started →</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
