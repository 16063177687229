import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../style/folder-detail.css';
import Layout from '../Layout';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const FolderDetail = ({ onLogout, user }) => {
  const { folderId } = useParams();
  const [folder, setFolder] = useState(null);
  const [videos, setVideos] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchFolderDetails();
  }, [folderId]);

  const fetchFolderDetails = async () => {
    try {
      const response = await axios.get(`https://api.threepio.io/transcribe/folders/${folderId}/videos/`);
      setFolder(response.data.folder);
      setVideos(response.data.videos);
    } catch (error) {
      console.error('Error fetching folder details:', error);
    }
  };

  const handleDeleteFolder = async () => {
    try {
      await axios.delete(`https://api.threepio.io/transcribe/folders/${folderId}/delete/`);
      navigate('/dashboard/folders');
    } catch (error) {
      console.error('Error deleting folder:', error);
    }
  };

  const handleRenameFolder = async () => {
    if (newFolderName.trim()) {
      try {
        const response = await axios.post(`https://api.threepio.io/transcribe/folders/${folderId}/rename/`, { name: newFolderName });
        if (response.data.error) {
          setErrorMessage(response.data.error);
        } else {
          setIsRenameModalOpen(false);
          setNewFolderName('');
          setErrorMessage('');
          fetchFolderDetails(); // Refresh folder details to reflect the new name
        }
      } catch (error) {
        console.error('Error renaming folder:', error);
      }
    } else {
      setErrorMessage('Folder name is required');
    }
  };

  const handleCheckboxChange = (videoId) => {
    const updatedSelectedVideos = selectedVideos.includes(videoId)
      ? selectedVideos.filter((id) => id !== videoId)
      : [...selectedVideos, videoId];
    setSelectedVideos(updatedSelectedVideos);
  };

  const handleDeleteVideos = async () => {
    try {
      await axios.post('https://api.threepio.io/transcribe/delete_videos/', { videoIds: selectedVideos });
      setSelectedVideos([]);
      fetchFolderDetails(); // Refresh folder details after deletion
    } catch (error) {
      console.error('Error deleting videos:', error);
    }
  };

  const handleRemoveVideosFromFolder = async () => {
    try {
      await axios.post('https://api.threepio.io/transcribe/remove_videos_from_folder/', { videoIds: selectedVideos, folderId });
      setSelectedVideos([]);
      fetchFolderDetails(); // Refresh folder details after removal
    } catch (error) {
      console.error('Error removing videos from folder:', error);
    }
  };

  const handleVideoClick = (videoId) => {
    navigate(`/video/${videoId}`);
  };

  return (
    <Layout onLogout={onLogout} user={user}>
      <div className="folder-detail-container">
        <div className="folder-detail-header">
          <h1>{folder ? folder.name : 'Folder Videos'}</h1>
          <div className="header-buttons">
            <button onClick={() => setIsRenameModalOpen(true)} className="rename-folder-button">
              Rename
            </button>
            <button onClick={() => setIsDeleteModalOpen(true)} className="delete-folder-button">
              Delete Folder
            </button>
            <button onClick={() => navigate(`/dashboard/create?folderId=${folderId}`)} className="create-button">
              Create
            </button>
          </div>
        </div>

        <Modal
          isOpen={isDeleteModalOpen}
          onRequestClose={() => setIsDeleteModalOpen(false)}
          contentLabel="Delete Folder"
          className="Modal"
          overlayClassName="Overlay"
        >
          <h2>Delete Folder</h2>
          <p>Are you sure you want to delete this folder? The videos inside will not be deleted.</p>
          <button onClick={handleDeleteFolder}>Yes, Delete</button>
          <button onClick={() => setIsDeleteModalOpen(false)}>Cancel</button>
        </Modal>

        <Modal
          isOpen={isRenameModalOpen}
          onRequestClose={() => setIsRenameModalOpen(false)}
          contentLabel="Rename Folder"
          className="Modal"
          overlayClassName="Overlay"
        >
          <h2>Rename Folder</h2>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <input
            type="text"
            value={newFolderName}
            onChange={(e) => setNewFolderName(e.target.value)}
            placeholder="New folder name"
            className="rename-folder-input"
          />
          <button onClick={handleRenameFolder} className="rename-folder-button">
            Rename
          </button>
          <button onClick={() => setIsRenameModalOpen(false)} className="cancel-button">
            Cancel
          </button>
        </Modal>

        {selectedVideos.length > 0 && (
          <div className="action-buttons">
            <button onClick={handleDeleteVideos} className="delete-videos-button">
              Delete Selected Videos
            </button>
            <button onClick={handleRemoveVideosFromFolder} className="remove-videos-button">
              Remove Selected from Folder
            </button>
          </div>
        )}

        {videos.length === 0 ? (
          <p>This folder is empty.</p>
        ) : (
          <table className="video-table">
            <thead>
              <tr>
                <th></th>
                <th>Name</th>
                <th>Date Uploaded</th>
                <th>Length</th>
                <th>Language</th>
              </tr>
            </thead>
            <tbody>
              {videos.map((video) => (
                <tr key={video.id} className="video-item">
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedVideos.includes(video.id)}
                      onChange={() => handleCheckboxChange(video.id)}
                    />
                  </td>
                  <td className="video-link" onClick={() => handleVideoClick(video.id)}>{video.title}</td>
                  <td>{new Date(video.created_at).toLocaleDateString()}</td>
                  <td>{video.length}</td>
                  <td>{video.language}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </Layout>
  );
};

export default FolderDetail;
