import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import '../../style/video-detail.css';
import Layout from '../Layout';
import { ip_addr, front_end_addr } from '../settings';

// Ensure react-modal is properly initialized
Modal.setAppElement('#root');

const VideoDetail = ({ onLogout, user }) => {
  const { videoId } = useParams();
  const [video, setVideo] = useState(null);
  const [displayOptions, setDisplayOptions] = useState({
    transcription: true,
    translation: true,
    subtitles: true,
    translatedSubtitles: true,
  });
  const [editMode, setEditMode] = useState(null); // To track which section is being edited
  const [editValues, setEditValues] = useState({
    transcript_text: '',
    translated_text: '',
    transcript_subtitles: '',
    translated_subtitles: '',
  });

  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [newTitle, setNewTitle] = useState('');
  const navigate = useNavigate();

  const fetchVideoDetails = useCallback(async () => {
    try {
      const response = await axios.get(`https://api.threepio.io/transcribe/video/${videoId}/`, {
        withCredentials: true,
      });
      if (response.data) {
        setVideo(response.data);
        setNewTitle(response.data.title);
        setEditValues({
          transcript_text: response.data.transcript_text,
          translated_text: response.data.translated_text,
          transcript_subtitles: response.data.transcript_subtitles,
          translated_subtitles: response.data.translated_subtitles,
        });
      } else {
        console.error('Invalid JSON response');
      }
    } catch (error) {
      console.error('Error fetching video details', error);
    }
  }, [videoId]);

  useEffect(() => {
    fetchVideoDetails();
  }, [fetchVideoDetails]);

  const getCsrfToken = () => {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; cookies.length > i; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, 10) === 'csrftoken=') {
          cookieValue = decodeURIComponent(cookie.substring(10));
          break;
        }
      }
    }
    return cookieValue;
  };

  const handleDelete = async () => {
    const csrfToken = getCsrfToken();
    try {
      console.log('Attempting to delete video');
      await axios.post(`https://api.threepio.io/transcribe/delete_video/${videoId}/`, null, {
        headers: {
          'X-CSRFToken': csrfToken,
        },
        withCredentials: true,
      });
      console.log('Video deleted successfully');
      navigate('/dashboard/database');
    } catch (error) {
      console.error('Error deleting video', error);
    } 
  };

  const handleRename = async () => {
    const csrfToken = getCsrfToken();
    try {
      await axios.post(`https://api.threepio.io/transcribe/update_video_title/${videoId}/`, {
        title: newTitle,
      }, {
        headers: {
          'X-CSRFToken': csrfToken,
        },
        withCredentials: true,
      });
      setVideo((prevVideo) => ({
        ...prevVideo,
        title: newTitle,
      }));
      setIsEditingTitle(false);
    } catch (error) {
      console.error('Error renaming video', error);
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setDisplayOptions((prevOptions) => ({
      ...prevOptions,
      [name]: checked,
    }));
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const toggleEditMode = (section) => {
    setEditMode((prevMode) => (prevMode === section ? null : section));
  };

  const saveChanges = async (section) => {
    const csrfToken = getCsrfToken();
    try {
      const response = await axios.post(`https://api.threepio.io/transcribe/edit_${section}/`, {
        video_id: videoId,  // Include the video ID here
        [section]: editValues[section],
      }, {
        headers: {
          'X-CSRFToken': csrfToken,
        },
        withCredentials: true,
      });
      if (response.status === 200) {
        setVideo((prevVideo) => ({
          ...prevVideo,
          [section]: editValues[section],
        }));
        toggleEditMode(section); // Turn off edit mode after successful save
      } else {
        console.error(`Error saving ${section}`);
      }
    } catch (error) {
      console.error(`Error saving ${section}`, error);
    }
  };

  const handleDownload = (type) => {
    let content = '';
    let fileName = '';
    if (type === 'transcript_text') {
      content = editValues.transcript_text;
      fileName = 'transcript_text.txt';
    } else if (type === 'translated_text') {
      content = editValues.translated_text;
      fileName = 'translated_text.txt';
    } else if (type === 'transcript_subtitles') {
      content = editValues.transcript_subtitles;
      fileName = 'transcript_subtitles.srt';
    } else if (type === 'translated_subtitles') {
      content = editValues.translated_subtitles;
      fileName = 'translated_subtitles.srt';
    }

    const blob = new Blob([content], { type: 'text/plain' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  const handleTitleClick = () => {
    setIsEditingTitle(true);
  };

  const handleTitleChange = (e) => {
    setNewTitle(e.target.value);
  };

  if (!video) {
    return <div>Loading...</div>;
  }

  return (
    <Layout onLogout={onLogout} user={user}>
      <div className="video-detail-container">
        <div className="video-detail-header">
          {isEditingTitle ? (
            <input
              type="text"
              value={newTitle}
              onChange={handleTitleChange}
              autoFocus
              className="edit-title-input"
            />
          ) : (
            <h1 onClick={handleTitleClick}>{video.title}</h1>
          )}
          <div>
            {isEditingTitle ? (
              <button onClick={handleRename} className="rename-button">Save</button>
            ) : (
              <button onClick={() => setIsEditingTitle(true)} className="rename-button">Rename</button>
            )}
            <button onClick={handleDelete} className="delete-button">Delete Video</button>
          </div>
        </div>
        <video width="640" height="360" controls>
          <source src={`${ip_addr}${video.video_file}`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="checkboxes">
          <label>
            <input
              type="checkbox"
              name="transcription"
              checked={displayOptions.transcription}
              onChange={handleCheckboxChange}
            />
            Transcript
          </label>
          <label>
            <input
              type="checkbox"
              name="translation"
              checked={displayOptions.translation}
              onChange={handleCheckboxChange}
            />
            Translated Text
          </label>
          <label>
            <input
              type="checkbox"
              name="subtitles"
              checked={displayOptions.subtitles}
              onChange={handleCheckboxChange}
            />
            Transcript Subtitles
          </label>
          <label>
            <input
              type="checkbox"
              name="translatedSubtitles"
              checked={displayOptions.translatedSubtitles}
              onChange={handleCheckboxChange}
            />
            Translated Subtitles
          </label>
        </div>
        {displayOptions.transcription && (
          <>
            <h2>Transcript</h2>
            {editMode === 'transcript_text' ? (
              <div className="edit-mode">
                <textarea
                  name="transcript_text"
                  value={editValues.transcript_text}
                  onChange={handleEditChange}
                />
                <button className='edit-button' onClick={() => saveChanges('transcript_text')}>Save</button>
                <button className='edit-button' onClick={() => toggleEditMode('transcript_text')}>Cancel</button>
              </div>
            ) : (
              <>
                <p>{video.transcript_text}</p>
                <button className='edit-button' onClick={() => toggleEditMode('transcript_text')}>Edit</button>
              </>
            )}
            <button className='download-button' onClick={() => handleDownload('transcript_text')}>Download Transcript Text</button>
          </>
        )}
        {displayOptions.translation && (
          <>
            <h2>Translated Text</h2>
            {editMode === 'translated_text' ? (
              <div className="edit-mode">
                <textarea
                  name="translated_text"
                  value={editValues.translated_text}
                  onChange={handleEditChange}
                />
                <button className='edit-button' onClick={() => saveChanges('translated_text')}>Save</button>
                <button className='edit-button' onClick={() => toggleEditMode('translated_text')}>Cancel</button>
              </div>
            ) : (
              <>
                <p>{video.translated_text}</p>
                <button className='edit-button' onClick={() => toggleEditMode('translated_text')}>Edit</button>
              </>
            )}
            <button className='download-button' onClick={() => handleDownload('translated_text')}>Download Translated Text</button>
          </>
        )}
        {displayOptions.subtitles && (
          <>
            <h2>Transcript Subtitles</h2>
            {editMode === 'transcript_subtitles' ? (
              <div className="edit-mode">
                <textarea
                  name="transcript_subtitles"
                  value={editValues.transcript_subtitles}
                  onChange={handleEditChange}
                />
                <button className='edit-button' onClick={() => saveChanges('transcript_subtitles')}>Save</button>
                <button className='edit-button' onClick={() => toggleEditMode('transcript_subtitles')}>Cancel</button>
              </div>
            ) : (
              <>
                <pre>{video.transcript_subtitles}</pre>
                <button className='edit-button' onClick={() => toggleEditMode('transcript_subtitles')}>Edit</button>
              </>
            )}
            <button className='download-button' onClick={() => handleDownload('transcript_subtitles')}>Download Transcript Subtitles</button>
          </>
        )}
        {displayOptions.translatedSubtitles && (
          <>
            <h2>Translated Subtitles</h2>
            {editMode === 'translated_subtitles' ? (
              <div className="edit-mode">
                <textarea
                  name="translated_subtitles"
                  value={editValues.translated_subtitles}
                  onChange={handleEditChange}
                />
                <button className='edit-button' onClick={() => saveChanges('translated_subtitles')}>Save</button>
                <button className='edit-button' onClick={() => toggleEditMode('translated_subtitles')}>Cancel</button>
              </div>
            ) : (
              <>
                <pre>{video.translated_subtitles}</pre>
                <button className='edit-button' onClick={() => toggleEditMode('translated_subtitles')}>Edit</button>
              </>
            )}
            <button className='download-button' onClick={() => handleDownload('translated_subtitles')}>Download Translated Subtitles</button>
          </>
        )}
      </div>
    </Layout>
  );
};

export default VideoDetail;
