import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import Layout from '../Layout';
import '../../style/Create.css';
import { ip_addr } from '../settings';

const Create = ({ onLogout, user }) => {
  const [file, setFile] = useState(null);
  const [language, setLanguage] = useState('en');
  const [translateLanguage, setTranslateLanguage] = useState('en');
  const [useCase, setUseCase] = useState('');
  const [maxSubtitleDuration, setMaxSubtitleDuration] = useState('');
  const [loading, setLoading] = useState(false);
  const [supportedLanguages, setSupportedLanguages] = useState([]);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [activeSection, setActiveSection] = useState('');
  const [editMode, setEditMode] = useState(null);
  const [editValues, setEditValues] = useState({
    transcriptText: '',
    translatedText: '',
    transcriptSubtitles: '',
    translatedSubtitles: '',
  });
  const [generationDuration, setGenerationDuration] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  const folderId = new URLSearchParams(location.search).get('folderId');

  useEffect(() => {
    const fetchSupportedLanguages = async () => {
      try {
        const response = await axios.get('https://api.threepio.io/transcribe/', {
          withCredentials: true,
          headers: {
            'Accept': 'text/html',
          },
        });
        const parser = new DOMParser();
        const doc = parser.parseFromString(response.data, 'text/html');
        const languageOptions = Array.from(doc.querySelectorAll('select#id_language option')).map(
          (option) => option.value
        );
        setSupportedLanguages(languageOptions);
      } catch (error) {
        console.error('Error fetching supported languages', error);
      }
    };
    fetchSupportedLanguages();
  }, []);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleToggleChange = (section) => {
    setActiveSection((prevSection) => (prevSection === section ? '' : section));
  };

  const handleGenerateSubtitles = async () => {
    if (!file) {
      alert('Please select a file.');
      return;
    }
    setLoading(true);
    setShowSaveButton(false);

    // Clear previous results
    setEditValues({
      transcriptText: '',
      translatedText: '',
      transcriptSubtitles: '',
      translatedSubtitles: '',
    });
    setActiveSection('');

    const formData = new FormData();
    formData.append('file', file);
    formData.append('language', language);
    formData.append('translate_language', translateLanguage);
    formData.append('use_case', useCase);
    formData.append('max_subtitle_duration', maxSubtitleDuration);

    // const csrfToken = getCookie('csrftoken');

    const startTime = performance.now();  // Start timing

    try {
      const response = await axios.post('https://api.threepio.io/transcribe/', formData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          // 'X-CSRFToken': csrfToken,
        },
      });

      const endTime = performance.now();  // End timing
      const duration = (endTime - startTime) / 1000; // Convert to seconds
      console.log(`Subtitle generation took ${duration.toFixed(2)} seconds`);

      const { transcript_text, translated_text, transcript_subtitles, translated_subtitles } = response.data;
      setEditValues({
        transcriptText: transcript_text,
        translatedText: translated_text,
        transcriptSubtitles: transcript_subtitles,
        translatedSubtitles: translated_subtitles,
      });
      setShowSaveButton(true);
      setGenerationDuration(duration); // Update the duration state
    } catch (error) {
      console.error('Error generating subtitles', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = (type) => {
    let content = '';
    let fileName = '';
    if (type === 'transcript') {
      content = editValues.transcriptSubtitles;
      fileName = 'transcript_subtitles.srt';
    } else if (type === 'translated') {
      content = editValues.translatedSubtitles;
      fileName = 'translated_subtitles.srt';
    } else if (type === 'transcriptText') {
      content = editValues.transcriptText;
      fileName = 'transcript_text.txt';
    } else if (type === 'translatedText') {
      content = editValues.translatedText;
      fileName = 'translated_text.txt';
    }

    const blob = new Blob([content], { type: 'text/plain' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleSaveVideo = async () => {
    try {
      const response = await axios.post(`${ip_addr}/transcribe/save_video/`, {
        transcript_text: editValues.transcriptText,
        translated_text: editValues.translatedText,
        transcript_subtitles: editValues.transcriptSubtitles,
        translated_subtitles: editValues.translatedSubtitles,
        folder_id: folderId, // Include folder ID in the request
      }, {
        withCredentials: true,
        headers: {
          'X-CSRFToken': getCookie('csrftoken'),
        },
      });
      if (response.status === 200) {
        alert('Video saved successfully.');
        navigate('/dashboard/database')
      } else {
        alert('Error saving video.');
      }
    } catch (error) {
      console.error('Error saving video', error);
      alert('Error saving video.');
    }
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const toggleEditMode = (section) => {
    setEditMode((prevMode) => (prevMode === section ? null : section));
  };

  return (
    <div className="color">
    <Layout onLogout={onLogout} user={user}>
    <a href="/dashboard/database" className="breadcrumb"><i className="fas fa-arrow-left"></i>Back to Project Overview</a>
        <h2 className="center">Create New Project</h2>
        <div className="create-container">
        <div className="form-group">
          <label htmlFor="file">Choose an audio file</label>
          <input type="file" id="file" onChange={handleFileChange} />
        </div>
        <div className="form-group">
          <label htmlFor="language">Audio Language</label>
          <select id="language" value={language} onChange={(e) => setLanguage(e.target.value)}>
            {supportedLanguages.map((lang) => (
              <option key={lang} value={lang}>{lang}</option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="translate_language">Translate Language</label>
          <select id="translate_language" value={translateLanguage} onChange={(e) => setTranslateLanguage(e.target.value)}>
            {supportedLanguages.map((lang) => (
              <option key={lang} value={lang}>{lang}</option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="use_case">Use Case
            <span className="hint"> - Optional but recommended for better accuracy</span>
          </label>
          <input
            type="text"
            id="use_case"
            name="useCase"
            value={useCase}
            onChange={(e) => setUseCase(e.target.value)}
            placeholder="e.g. Advert, Documentary etc."
          />
        </div>
        <div className="form-group">
          <label htmlFor="max_subtitle_duration">Max Subtitle Duration
            <span className="hint"> - For subtitle generation </span>
          </label>
          <input
            type="text"
            id="max_subtitle_duration"
            name="maxSubtitleDuration"
            value={maxSubtitleDuration}
            onChange={(e) => setMaxSubtitleDuration(e.target.value)}
            placeholder="e.g. 3."
          />
        </div>
        <button onClick={handleGenerateSubtitles} disabled={loading}>
          {loading ? 'Generating...' : 'Generate'}
        </button>

        {showSaveButton && (
          <div>
            <div className="toggle-buttons">
              <button
                className={`toggle-button ${activeSection === 'transcription' ? 'active' : ''}`}
                onClick={() => handleToggleChange('transcription')}
              >
                Transcription
              </button>
              <button
                className={`toggle-button ${activeSection === 'translation' ? 'active' : ''}`}
                onClick={() => handleToggleChange('translation')}
              >
                Translation
              </button>
              <button
                className={`toggle-button ${activeSection === 'subtitles' ? 'active' : ''}`}
                onClick={() => handleToggleChange('subtitles')}
              >
                Subtitles
              </button>
              <button
                className={`toggle-button ${activeSection === 'translatedSubtitles' ? 'active' : ''}`}
                onClick={() => handleToggleChange('translatedSubtitles')}
              >
                Translated Subtitles
              </button>
            </div>
            {generationDuration && (
              <p>Subtitle generation took {generationDuration.toFixed(2)} seconds</p>
            )}
          </div>
        )}

        {activeSection === 'transcription' && (
          <div className="result-section">
            <h3>Transcript Text</h3>
            {editMode === 'transcription' ? (
              <div className="edit-mode">
                <textarea
                  name="transcriptText"
                  value={editValues.transcriptText}
                  onChange={handleEditChange}
                />
                <button className="edit-button" onClick={() => toggleEditMode('transcription')}>Cancel</button>
              </div>
            ) : (
              <pre>{editValues.transcriptText}</pre>
            )}
            <button className="edit-button" onClick={() => toggleEditMode('transcription')}>
              {editMode === 'transcription' ? 'Save' : 'Edit'}
            </button>
            <button onClick={() => handleDownload('transcriptText')}>Download Transcript Text</button>
          </div>
        )}

        {activeSection === 'translation' && (
          <div className="result-section">
            <h3>Translated Text</h3>
            {editMode === 'translation' ? (
              <div className="edit-mode">
                <textarea
                  name="translatedText"
                  value={editValues.translatedText}
                  onChange={handleEditChange}
                />
                <button className="edit-button" onClick={() => toggleEditMode('translation')}>Cancel</button>
              </div>
            ) : (
              <pre>{editValues.translatedText}</pre>
            )}
            <button className="edit-button" onClick={() => toggleEditMode('translation')}>
              {editMode === 'translation' ? 'Save' : 'Edit'}
            </button>
            <button onClick={() => handleDownload('translatedText')}>Download Translated Text</button>
          </div>
        )}

        {activeSection === 'subtitles' && (
          <div className="result-section">
            <h3>Subtitles in Original Language</h3>
            {editMode === 'subtitles' ? (
              <div className="edit-mode">
                <textarea
                  name="transcriptSubtitles"
                  value={editValues.transcriptSubtitles}
                  onChange={handleEditChange}
                />
                <button className="edit-button" onClick={() => toggleEditMode('subtitles')}>Cancel</button>
              </div>
            ) : (
              <pre>{editValues.transcriptSubtitles}</pre>
            )}
            <button className="edit-button" onClick={() => toggleEditMode('subtitles')}>
              {editMode === 'subtitles' ? 'Save' : 'Edit'}
            </button>
            <button onClick={() => handleDownload('transcript')}>Download Original Language Subtitles</button>
          </div>
        )}

        {activeSection === 'translatedSubtitles' && (
          <div className="result-section">
            <h3>Subtitles in Translated Language</h3>
            {editMode === 'translatedSubtitles' ? (
              <div className="edit-mode">
                <textarea
                  name="translatedSubtitles"
                  value={editValues.translatedSubtitles}
                  onChange={handleEditChange}
                />
                <button className="edit-button" onClick={() => toggleEditMode('translatedSubtitles')}>Cancel</button>
              </div>
            ) : (
              <pre>{editValues.translatedSubtitles}</pre>
            )}
            <button className="edit-button" onClick={() => toggleEditMode('translatedSubtitles')}>
              {editMode === 'translatedSubtitles' ? 'Save' : 'Edit'}
            </button>
            <button onClick={() => handleDownload('translated')}>Download Translated Subtitles</button>
          </div>
        )}

        {showSaveButton && (
          <button onClick={handleSaveVideo} className="save-video-button">
            Save Video
          </button>
        )}
      </div>
    </Layout>
    </div>
  );
};

function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export default Create;
