import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import axios from 'axios';
import '../../style/overview.css';
import '../../style/home.css';
import '../../style/Plans.css';
import BillingNav from '../dashboard/billing-nav';
import { front_end_addr } from '../settings';

const stripePromise = loadStripe('pk_test_51Pfq46RoxdHikx08BsalnhhhzWUaCRlqqIVKZbjtrPQ83lDTriesgHJVsBRHdp93PIcg1tmP4cu128p77rsmWUDi00TQ2NnZat');

const Plans = ({ onLogout, user }) => {
  const { username } = user; // Corrected destructuring

  const [selectedPlan, setSelectedPlan] = useState(null);
  const [billingCycle, setBillingCycle] = useState('monthly');
  const [paymentMethodType, setPaymentMethodType] = useState('card');

  const handleSelectPlan = (plan) => {
    setSelectedPlan(plan);
  };

  const toggleBillingCycle = (cycle) => {
    setBillingCycle(cycle);
  };

  const handleContinueToPayment = async () => {
    try {
      const response = await axios.post('https://threepio.io/billing/create-checkout-session/', {
        priceId: selectedPlan.priceId,
        username: username,
        planName: selectedPlan.name,
        paymentMethodType: paymentMethodType,
      });
      const { id } = response.data;

      const stripe = await stripePromise;
      stripe.redirectToCheckout({ sessionId: id });
    } catch (error) {
      console.error('Error creating checkout session:', error);
    }
  };

  const plans = [
    {
      name: 'Pay-as-you-go',
      price: '0',
      priceId: 'price_1PfsliRoxdHikx08yCVArAeP',
      description: 'Free forever',
      features: [
        'AI-powered transcription, translation, and subtitles',
        '120 minutes of subtitles a month',
        'Purchase additional minutes at rate of $0.25/minute',
        'Download transcriptions in Word and TXT',
        'Download subtitles in SRT',
      ],
    },
    {
      name: 'Basic',
      price: billingCycle === 'monthly' ? '16' : '115.20',
      priceId: billingCycle === 'monthly' ? 'price_1PfsliRoxdHikx08TiY5HgoG' : 'price_1PfsliRoxdHikx08z4VX9Rrn',
      description: billingCycle === 'monthly' ? 'Billed monthly' : 'Billed annually',
      features: [
        'AI-powered transcription, translation, and subtitles',
        '120 minutes of subtitles a month',
        'Purchase additional minutes at rate of $0.20/minute',
        'Download transcriptions in Word and TXT',
        'Download subtitles in SRT',
        'Video search and analytics function',
      ],
    },
    {
      name: 'Pro',
      price: billingCycle === 'monthly' ? '28' : '201.60',
      priceId: billingCycle === 'monthly' ? 'price_1PfsliRoxdHikx08fudbTb7y' : 'price_1PfsliRoxdHikx08HvQwPokw',
      description: billingCycle === 'monthly' ? 'Billed monthly' : 'Billed annually',
      features: [
        'AI-powered transcription, translation, and subtitles',
        '300 minutes of subtitles a month',
        'Purchase additional minutes at rate of $0.15/minute',
        'Download transcriptions in Word and TXT',
        'Download subtitles in SRT',
        'Video search and analytics function',
      ],
    },
    {
      name: 'Business',
      price: billingCycle === 'monthly' ? '52' : '374.40',
      priceId: billingCycle === 'monthly' ? 'price_1PfsliRoxdHikx08W6G1GXm8' : 'price_1PfsliRoxdHikx08uQWman7P',
      description: billingCycle === 'monthly' ? 'Billed monthly' : 'Billed annually',
      features: [
        'AI-powered transcription, translation, and subtitles',
        '600 minutes of subtitles a month',
        'Purchase up to 10 extra hours',
        'Download transcriptions in Word and TXT',
        'Download subtitles in SRT',
        'Video search and analytics function',
        'Team collaboration',
      ],
    },
  ];

  return (
    <Elements stripe={stripePromise}>
      {/* <BillingNav /> */}
      <a href="/dashboard/billing" className="breadcrumb"><i className="fas fa-arrow-left"></i>Back to Billing Overview</a>
      <div className="plans-page">
        <div className="center-header">
          <h1>Choose Your Plan</h1>
            <div className="plans-header">
              <div className="pricing-toggle">
                <button
                    className={`toggle-button ${billingCycle === 'monthly' ? 'active' : ''}`}
                    onClick={() => toggleBillingCycle('monthly')}
                  >
                    Monthly
                  </button>
                  <button
                    className={`toggle-button ${billingCycle === 'yearly' ? 'active' : ''}`}
                    onClick={() => toggleBillingCycle('yearly')}
                  >
                    Yearly
                  </button>
              </div>
            </div>
        </div>
        <div className="plans-container">
          <div className="plans">
            {plans.map((plan, index) => (
              <div key={index} className={`plan ${selectedPlan && selectedPlan.name === plan.name ? 'selected' : ''}`}>
                <h3>{plan.name}</h3>
                {/* <p>{plan.description}</p> */}
                {/* // <div className="price-wrapper"> */}
                  <p className="price">${plan.price}</p>
                {/* // </div> */}
                <ul>
                  {plan.features.map((feature, i) => (
                    <li key={i}>{feature}</li>
                  ))}
                </ul>
                <button onClick={() => handleSelectPlan(plan)} className="select-button">Select</button>
              </div>
            ))}
          </div>
          {selectedPlan && (
            <div className="checkout-form-container">
              <div className="subscription-summary">
                <h4>Your Subscription Overview</h4>
                <p>Selected Plan: <strong>{selectedPlan.name} Plan</strong></p>
                <p>Total: ${selectedPlan.price} / {billingCycle}</p>
                <label htmlFor="payment-method" className="add-margin">Select Payment Method:</label>
                <select
                  id="payment-method"
                  value={paymentMethodType}
                  onChange={(e) => setPaymentMethodType(e.target.value)}
                  className="add-margin"
                >
                  <option value="card">Card</option>
                  <option value="us_bank_account">US Bank Account</option>
                  {/* Add more options as needed */}
                </select>
                <button onClick={handleContinueToPayment}>Continue to Payment</button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Elements>
  );
};

export default Plans;
