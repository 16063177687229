import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../style/signup.css'; 
import Navbar from './navbar';
import { ip_addr } from './settings';

function Signup() {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    firstName: '',
    lastName: '',
    password1: '',
    password2: ''
  });
  const [errors, setErrors] = useState({});
  const [isAgreementChecked, setIsAgreementChecked] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleCheckboxChange = (e) => {
    setIsAgreementChecked(e.target.checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isAgreementChecked) {
      setErrors({ agreement: 'You must agree to the privacy agreement to sign up.' });
      return;
    }
    const data = {
      username: formData.username,
      email: formData.email,
      first_name: formData.firstName,
      last_name: formData.lastName,
      password1: formData.password1,
      password2: formData.password2
    };
    axios.post('https://api.threepio.io/accounts/signup/', data, {
      headers: {
        'X-CSRFToken': getCookie('csrftoken'),
        'Content-Type': 'application/json'
      },
      withCredentials: true // Ensure cookies are sent with the request
    })
    .then(response => {
      if (response.data.success) {
        console.log('User signed up successfully', response.data);
        window.alert('Signup successful!');
        navigate('/login');
      } else {
        setErrors(response.data.message);
      }
    })
    .catch(error => {
      console.error('There was an error signing up!', error);
      if (error.response && error.response.data && error.response.data.message) {
        setErrors(error.response.data.message);
      } else {
        setErrors({ general: 'An error occurred during signup.' });
      }
    });
  };

  const getCookie = (name) => {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  };

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    setIsAuthenticated(false);
  };

  const agreement_link = `https://api.threepio.io/accounts/privacy_agreement/`
  
  return (
    <div className="login">
      <Navbar isAuthenticated={isAuthenticated} handleLogout={handleLogout} />
      <div className="signup-container">
        <h1>Sign Up</h1>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Username</label>
            <input
              type="text"
              name="username"
              placeholder="Username"
              onChange={handleChange}
              required
            />
            {errors.username && <div className="error">{errors.username[0]}</div>}
            <small>150 characters or fewer. Letters, digits and @/./+/-/_ only.</small>
          </div>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              name="email"
              placeholder="your_email_address@email.com"
              onChange={handleChange}
              required
            />
            {errors.email && <div className="error">{errors.email[0]}</div>}
            <small>Please enter a valid email address.</small>
          </div>
          <div className="form-group">
            <label>First name</label>
            <input
              type="text"
              name="firstName"
              placeholder="First Name"
              onChange={handleChange}
              required
            />
            {errors.first_name && <div className="error">{errors.first_name[0]}</div>}
            <small>Please enter your first name.</small>
          </div>
          <div className="form-group">
            <label>Last name</label>
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              onChange={handleChange}
              required
            />
            {errors.last_name && <div className="error">{errors.last_name[0]}</div>}
            <small>Please enter your last name.</small>
          </div>
          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              name="password1"
              placeholder="Password"
              onChange={handleChange}
              required
            />
            {errors.password1 && <div className="error">{errors.password1[0]}</div>}
            <small>
              Your password can’t be too similar to your other personal information. Your password must contain at least 8 characters. Your password can’t be a commonly used password. Your password can’t be entirely numeric.
            </small>
          </div>
          <div className="form-group">
            <label>Password confirmation</label>
            <input
              type="password"
              name="password2"
              placeholder="Password Confirmation"
              onChange={handleChange}
              required
            />
            {errors.password2 && <div className="error">{errors.password2[0]}</div>}
            <small>Enter the same password as before, for verification.</small>
          </div>
         
            <div className="checkbox-group">
              <input
                type="checkbox"
                name="agreement"
                id="agreement-checkbox"
                checked={isAgreementChecked}
                onChange={handleCheckboxChange}
              />
              <label htmlFor="agreement-checkbox">
                I agree to the <a href={agreement_link} target="_blank" rel="noreferrer">privacy agreement</a>
              </label>
            </div>
            {errors.agreement && <div className="error">{errors.agreement}</div>}
          <button type="submit">Sign Up</button>
          {errors.general && <div className="error">{errors.general}</div>}
        </form>
        <p>If you already have an account, <a href="/login">Log In</a></p>
      </div>
    </div>
  );
}

export default Signup;
