import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../style/navbar.css';
import Logo from '../style/Logo.png';

function Navbar({ isAuthenticated, handleLogout, user }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <nav className="navbar">
        {isAuthenticated ? (
          <>
            <div className="navbar-logo logo">
              <a class="color" href="/">
                Threepio
                {/* <img src={Logo} alt="Threepio" className="logo" /> */}
              </a>
            </div>
            <div className="navbar-links">
              <div className="welcome-user">
                <span>Welcome! </span>
                <span className="username">{user.username}</span>
              </div>
              <Link to="/dashboard/database" className="logout-button">Projects</Link>              
              <Link to="/dashboard/billing" className="logout-button">Billing</Link>
              <Link to="/dashboard/create" className="logout-button">Create</Link>
              <button onClick={handleLogout} className="logout-button">Logout</button>
            </div>
          </>
        ) : (
          <>
            <div className="navbar-logo">
            <Link to="/" className="navbar-logo logo">
              <a class="color" href="/">
                {/* <img src={Logo} alt="Threepio" className="logo" /> */}
                Threepio
              </a>
            </Link>
            </div>
            <div className="navbar-links">
            <div className="dropdown" onMouseEnter={toggleDropdown} onMouseLeave={toggleDropdown}>
              <button className="dropdown-button">Solutions</button>
              {dropdownOpen && (
                <div className="dropdown-content">
                  <Link to="/login">
                    <span className="dropdown-title">Transcribe</span>
                    <span className="dropdown-description">Effortless AI transcriptions in 100+ languages.</span>
                  </Link>
                  <Link to="/login">
                    <span className="dropdown-title">Translate</span>
                    <span className="dropdown-description">Break barriers with AI translations in 100+ languages.</span>
                  </Link>
                  <Link to="/login">
                    <span className="dropdown-title">Subtitles</span>
                    <span className="dropdown-description">Dynamic, context-aware AI subtitles in any language.</span>
                  </Link>
                </div>
              )}
            </div>
            <Link to="/pricing">Pricing</Link>
            <Link to="/login">Sign in</Link>
            <Link to="/signup" className="signup-button">Sign up</Link>
            </div>
          </>
        )}
    </nav>
  );
}

export default Navbar;
