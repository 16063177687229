import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from './navbar';
import '../style/Layout.css';
import axios from 'axios';
import { ip_addr } from './settings';

const Layout = ({ onLogout, user, children }) => {
  const handleLogout = async () => {
    try {
      const getCookie = (name) => {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
          const cookies = document.cookie.split(';');
          for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
              cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
              break;
            }
          }
        }
        return cookieValue;
      };

      const response = await axios.post('https://api.threepio.io/accounts/logout/', null, {
        withCredentials: true,
        headers: {
          'X-CSRFToken': getCookie('csrftoken'),
        },
      });
      console.log('User logged out successfully', response.data);
      onLogout();
      alert('Logged out successfully');
    } catch (error) {
      console.error('There was an error logging out!', error);
      alert('Error logging out');
    }
  };

  return (
    <div className="layout-container">
      <Navbar isAuthenticated={true} handleLogout={handleLogout} user={user} />
      <div className="layout-content">
        {/* <aside className="sidebar">
          <ul>
            <li><Link to="/dashboard/database">Database</Link></li>
            <li><Link to="/dashboard/create">Create</Link></li>
            <li><Link to="/dashboard/folders">Folders</Link></li>
            <li><Link to="/dashboard/trash">Trash</Link></li>
            <li><Link to="/dashboard/billing">Billing</Link></li>
          </ul>
        </aside> */}
        <main className="main-content">
          {children}
        </main>
      </div>
    </div>
  );
};

export default Layout;
